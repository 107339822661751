import React from "react"
import s from "./Footer.module.scss"

const Footer = () => {
    return (
        <footer className={s.container}>
            <ul className={s.footerItems}>
                <li>
                    © {new Date().getFullYear()}{" "}
                    <span className={s.prd}>P.R.D</span> Interiors UK
                </li>
            </ul>
        </footer>
    )
}

export default Footer
