import React from "react"
import s from "./portfolio.module.scss"
import Layout from "../layout"
import Job from "../../molecules/Job"
import Heading from "../../atoms/Heading"
import ContentContainer from "../../atoms/ContentContainer"
import Subheading from "../../atoms/Subheading"

function Portfolio({ pageContext }) {
    return (
        <Layout>
            <ContentContainer>
                <div className={s.pageIntro}>
                    <Heading>{pageContext.title}</Heading>
                    <Subheading>{pageContext.description}</Subheading>
                    <hr />
                    <div className={s.grid}>
                        {pageContext.examples.map((example, index) => (
                            <Job key={`porfolio-item-${index}`} {...example} />
                        ))}
                    </div>
                </div>
            </ContentContainer>
        </Layout>
    )
}

export default Portfolio
