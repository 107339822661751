import React from "react"
import PropTypes from "prop-types"
import s from "./Heading.module.scss"

const Heading = ({ children }) => <h1 className={s.heading}>{children}</h1>

Heading.propTypes = {
    children: PropTypes.string,
}

Heading.defaultProps = {
    children: "",
}

export default Heading
