/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Banner from "../../atoms/Banner"
import SEO from "../../atoms/seo"
import Header from "../../molecules/Header"
import Footer from "../../molecules/Footer"

import s from "./layout.module.scss"
import "./styles.scss"

const Layout = ({ children }) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                        menuLinks {
                            name
                            link
                        }
                        contactDetails {
                            telephone
                            email
                        }
                    }
                }
            }
        `}
        render={data => (
            <Fragment>
                <SEO
                    title="Home"
                    keywords={[`gatsby`, `application`, `react`]}
                />
                <Header
                    siteTitle={data.site.siteMetadata.title}
                    menuLinks={data.site.siteMetadata.menuLinks}
                    contactDetails={data.site.siteMetadata.contactDetails}
                />
                <div className={s.siteContainer}>
                    <main className={s.siteContent}>{children}</main>
                </div>
                <Footer />
            </Fragment>
        )}
    />
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
