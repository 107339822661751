import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faBars, faPhone } from "@fortawesome/free-solid-svg-icons"

import s from "./MobileMenu.module.scss"

function MobileMenu({ menuLinks, contactDetails, title }) {
    const [showMobileMenu, setShowMobileMenu] = useState(false)

    function noscroll() {
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        if (typeof window === "undefined") return
        if (showMobileMenu) {
            window.addEventListener("scroll", noscroll)
        }
        return () => {
            window.removeEventListener("scroll", noscroll)
        }
    })

    function toggleMobileMenu() {
        setShowMobileMenu(!showMobileMenu)
    }

    return (
        <div className={s.mobileMenuContainer}>
            <div className={s.mobileMenuNav}>
                <div className={s.logoContainer}>
                    <Link to="/">
                        <img
                            className={s.logo}
                            src="https://res.cloudinary.com/latitude55/image/upload/v1558179861/prd_interiors/logo/logo_mobile.png"
                            alt={title}
                        />
                    </Link>
                </div>
                <div className={s.mobileMenu}>
                    <button
                        type="button"
                        className={s.mobileMenuButton}
                        onClick={toggleMobileMenu}
                    >
                        <FontAwesomeIcon
                            icon={showMobileMenu ? faTimes : faBars}
                            size={"2x"}
                        />
                    </button>
                </div>
                <div className={s.telephoneNumber}>
                    <FontAwesomeIcon icon={faPhone} size={"1x"} />
                    <a href={`tel: ${contactDetails.telephone}`}>
                        {contactDetails.telephone}
                    </a>
                </div>
            </div>
            <div
                className={
                    showMobileMenu ? s.mobileMenuDropdown : s.mobileMenuHidden
                }
            >
                <ul className={s.mobileMenuItems}>
                    {menuLinks.map((x, i) => (
                        <li key={i} onClick={toggleMobileMenu}>
                            <Link to={x.link} activeClassName={s.active}>
                                {x.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default MobileMenu
