import React, { useState, useEffect, Fragment } from "react"
import LB from "react-image-lightbox"
import s from "./Job.module.scss"
import "react-image-lightbox/style.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

function _createImagesArray(imgsUrl, no_imgs) {
    let imagesArray = []
    return function createImageUrl(image) {
        if (image > no_imgs) return imagesArray

        imagesArray.push(`${imgsUrl}/image_${image}`)
        return createImageUrl(image + 1)
    }
}

function Job({
    isOpen,
    description,
    tasks,
    imgsUrl,
    title,
    no_imgs,
    grid = false,
}) {
    const createImagesArray = _createImagesArray(imgsUrl, no_imgs)
    const [lightboxIsOpen, setLightboxIsOpen] = useState(isOpen)
    const [images, setImages] = useState([])
    const [currentImage, setCurrentImage] = useState(0)

    useEffect(() => {
        setImages(createImagesArray(1))
    }, [no_imgs])

    return (
        <Fragment>
            <div className={s.jobContainer}>
                <div className={s.imageContainer}>
                    <img
                        onClick={() =>
                            images.length && setLightboxIsOpen(!lightboxIsOpen)
                        }
                        src={`${imgsUrl}/image_1`
                            .replace(/w_750/, "w_400")
                            .replace(/c_scale/, "ar_1:1")}
                        alt={title}
                    />
                    <p>Click for more images</p>
                </div>
                <div className={s.description}>
                    <h2 className={s.title}>{title}</h2>
                    <ul className={s.services}>
                        {tasks &&
                            tasks.map((task, index) => (
                                <li key={`task-${index}`} className={s.service}>
                                    <span className={s.icon}>
                                        <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            size={"2x"}
                                        />
                                    </span>
                                    {task}
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
            {lightboxIsOpen && (
                <LB
                    mainSrc={images[currentImage]}
                    prevSrc={images[currentImage - 1]}
                    nextSrc={images[currentImage + 1]}
                    onMovePrevRequest={() => setCurrentImage(currentImage - 1)}
                    onMoveNextRequest={() => setCurrentImage(currentImage + 1)}
                    onCloseRequest={() => setLightboxIsOpen(!lightboxIsOpen)}
                    enableZoom={false}
                />
            )}
        </Fragment>
    )
}

export default Job
