import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import MobileMenu from "./MobileMenu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"

import s from "./Header.module.scss"

function renderMobile(props) {
    return (
        <header>
            <MobileMenu {...props} />
        </header>
    )
}

function renderDesktop({ menuLinks, contactDetails, title }) {
    return (
        <header>
            <div className={s.headerContainer}>
                <div className={s.logoContainer}>
                    <Link to="/">
                        <img
                            className={s.logo}
                            src="https://res.cloudinary.com/latitude55/image/upload/v1558179861/prd_interiors/logo/logo_mobile.png"
                            alt={title}
                        />
                    </Link>
                </div>
                <div className={s.menuContainer}>
                    {/* <ul className={s.contactDetailsContainer}>
                        <li className={s.contactDetailsItem}>
                            <FontAwesomeIcon icon={faPhone} size={"2x"} />
                            <span className={s.details}>
                                {contactDetails.telephone}
                            </span>
                        </li>
                        <li className={s.contactDetailsItem}>
                            <FontAwesomeIcon icon={faEnvelope} size={"2x"} />
                            <span className={s.details}>
                                {contactDetails.email}
                            </span>
                        </li>
                    </ul> */}
                    <div className={s.menuItemsContainer}>
                        <ul className={s.menuItems}>
                            {menuLinks.map((x, i) => (
                                <li key={i} className={s.menuItem}>
                                    <Link
                                        exact
                                        to={x.link}
                                        activeClassName={s.active}
                                    >
                                        {x.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

const Header = props => {
    const [width, setWidth] = useState(null)
    const mobileBreakpoint = 480

    function handleResize() {
        console.log(window.innerWidth)
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        handleResize()

        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])
    return width > mobileBreakpoint ? renderDesktop(props) : renderMobile(props)
}

Header.propTypes = {
    menuLinks: PropTypes.array,
}

Header.defaultProps = {
    menuLinks: null,
}

export default Header
